import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import { Link } from "gatsby"

import TabBar from "../components/policyTabs"
import Container from "../components/container"

export default function BookingTermsAndConditions({ location }) {
  return (
    <Page>
      <SEO title="Terms of Use" />
      <Container>
        <h1 className="tandc">Terms and Conditions</h1>
      </Container>
      <TabBar location={location} />
      <Container>
        <div className="text-container">
          <h1>Terms of Use</h1>

          <p>
            Thank you for visiting our website (the “Site”). This Site is owned
            and operated by TravelbyBit Pty Ltd (ABN 88 621 527 357). By
            accessing and using this Site and related services, you agree to
            these Terms of Use, which include our{" "}
            <Link to="/privacy-policy">Privacy Policy</Link> (the “Terms”). You
            should review these Terms carefully and immediately cease using our
            Site if you do not agree to these Terms.
          </p>
          <p>
            For terms and conditions concerning travel bookings, please see our{" "}
            <Link to="/booking-terms-and-conditions">
              Booking Terms and Conditions
            </Link>
            .
          </p>
          <p>In these Terms, ‘us’, ‘we’ and ‘our’ means TravelbyBit Pty Ltd.</p>

          <h3>1 Accuracy, Completeness and Timeliness of Information</h3>
          <p>
            This Site displays information about flights, accommodation, tours,
            digital currency wallets, the TravelbyBit payment system, merchant
            information and more. While we use all reasonable attempts to ensure
            the accuracy and completeness of the information on our Site, we
            make no warranty regarding the information on this Site, especially
            where that information is provided by third parties. We recommend
            confirming any information provided on our Site by third parties
            with the relevant third party.
          </p>
          <p>
            We may, from time to time and without notice, change or add to the
            Site (including the Terms) or the information, products or services
            described in it. However, we do not undertake to keep the Site
            updated. We are not liable to you or anyone else if errors occur in
            the information on the Site or if that information is not
            up-to-date.
          </p>

          <h3>2 Promotions and Competitions</h3>
          <p>
            For certain campaigns, promotions or contests, additional terms and
            conditions may apply. If you participate in such a campaign,
            promotion or contest, you must agree to the relevant terms and
            conditions applicable to that campaign, promotion or contest. In the
            event of any inconsistency between such terms and conditions and
            these Terms, those terms and conditions will prevail.
          </p>

          <h3>3 Linked Sites</h3>
          <p>
            Our Site may contain links to websites operated by third parties.
            Those links are provided for convenience and may not remain current
            or be maintained. We are not liable to you if interference with or
            damage to your computer systems occurs in connection with the use of
            this Site or any linked website. You must take your own precautions
            to ensure that whatever you select for your use from our Site is
            free of viruses or any other malware that may interfere with or
            damage the operations of your computer systems.
          </p>

          <h3>4 Intellectual Property</h3>
          <p>
            Unless otherwise indicated, we own, or license from third parties
            all rights, title and interest (including, without limitation,
            copyright, designs, patents, trademarks and other intellectual
            property rights) in this Site and in all of the material (including,
            without limitation, all text, graphics, logos, audio and software)
            made available on this Site (“Content”).
          </p>
          <p>
            Your use of this Site and use of and access to any Content does not
            grant or transfer any rights, title or interest to you in relation
            to this Site or the Content. However, we do grant you a licence to
            access the Site and view the Content on the terms and conditions set
            out in these Terms and, where applicable, as expressly authorised by
            us and/or our third party licensors.
          </p>
          <p>
            Any reproduction or redistribution of this Site or the Content is
            prohibited and may result in penalties. In addition, you must not
            copy the Content to any other server, location or support for
            publication, reproduction or distribution is expressly prohibited.
          </p>
          <p>
            All other use, copying or reproduction of this Site, the Content or
            any part of it is prohibited, except to the extent permitted by law.
          </p>

          <h3>5 Your Use</h3>
          <p>
            By using our Site, you agree that you will only use this site for
            lawful purposes.
          </p>
          <p>
            You must not do any act that is unlawful or is prohibited by any
            laws applicable to our Site, including (without limitation):
          </p>
          <ul>
            <li>
              any act that would constitute a breach of either the privacy
              (including uploading private or personal information without an
              individual’s consent) or any other of the legal rights of
              individuals;
            </li>
            <li>use this Site while impersonating another person;</li>
            <li>
              use this Site to defame or libel us, our employees or other
              individuals;
            </li>
            <li>
              transmit viruses that may cause damage to our property or the
              property of other individuals;
            </li>
            <li>
              posting or transmitting to this Site any non-authorised material
              including, but not limited to, material that is, in our opinion,
              likely to cause annoyance, or which is defamatory, racist,
              obscene, threatening, pornographic or otherwise or which is
              detrimental to or in violation of our systems or a third party’s
              systems or network security;
            </li>
            <li>
              tamper with, hinder the operation of or make unauthorised
              modifications to this Site (including deleting data from this Site
              without our permission);
            </li>
            <li>
              lbreach any third party’s rights (including intellectual property
              rights and obligations of confidentiality owed to third parties)
              or infringe any laws in any jurisdiction in using this Site.
            </li>
          </ul>
          <p>
            If we allow you to post any information to our Site, we have the
            right to take down this information at our sole discretion and
            without notice.
          </p>

          <h3>6 Your Warranties</h3>
          <p>You warrant that:</p>
          <ul>
            <li>
              you are of sufficient legal age and have the capacity to enter
              into a legally binding contract with us and the travel service
              providers.
            </li>
            <li>
              the information you provide to us, including personal information,
              is accurate, true, current and not misleading in any way.
            </li>
            <li>
              you will promptly update us on any changes to your information
              that may affect our ability to provide our services to you.
            </li>
          </ul>

          <h3>7 Disclaimers and Limitation of Liability</h3>
          <p>
            This Site is provided by us on an ‘as is’ basis. We do not warrant
            or represent the Content of this site is accurate, up-to-date or
            complete, or that it does not infringe the rights of any third
            parties. We make no representations or warranties of any kind with
            respect to the site, its content or any of the products supplied
            through the Site. To the maximum extent permitted by law, we
            disclaim all implied representations and warranties including
            without limitation, implied warranties that the Travel Products
            offered and supplied through the Site will be of merchantable
            quality, fit for any purpose or will comply with any description on
            the Site.
          </p>
          <p>
            To the extent permitted by law, we are not liable to you or anyone
            else (including for negligence, breach of contract or tort) for any
            loss or damage (including specific, indirect, consequential or
            economic loss) however caused and which is suffered directly or
            indirectly in connection with:
          </p>
          <ul>
            <li>
              the use of (or inability to use) this Site, the Service or any
              linked site;
            </li>
            <li>the disruption to this Site or the Service;</li>
            <li>
              the delivery or non-delivery of Travel Products acquired by using
              the Service; and
            </li>
            <li>
              any act or omission of Third Party Travel Providers or other third
              parties.
            </li>
          </ul>
          <p>
            To the maximum extent permitted by law, in no event shall we be
            liable for any direct and indirect loss, damage or expense
            (irrespective of the manner in which it occurs) which may be
            suffered due to your use of our Site and/or the information or
            materials contained on it, or as a result of the inaccessibility of
            this Site and/or the fact that certain information or materials
            contained on it are incorrect, incomplete or not up-to-date.
          </p>

          <h3>8 Jurisdiction and Governing Law</h3>
          <p>
            Your use of the Site and these Terms are governed by the law of
            Queensland, Australia and you submit to the non-exclusive
            jurisdiction of the courts exercising jurisdiction in Queensland.
          </p>
        </div>
      </Container>
    </Page>
  )
}

const Page = styled.div`
  min-height: 100vh;

  @media only screen and (max-width: 1169px) {
    padding: 0 1rem;
  }
`
